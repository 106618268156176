import React from "react";

const Backend = () => {
	return (
		<div className='skills__content'>
			<h3 className='skills__title'>Coursework/Others</h3>
			<div className='skills__box'>
				<div className='skills__group'>
					<div className='skills__data'>
						<i className='bx bx-badge-check'></i>
						<div>
							<h3 className='skills__name'>C++</h3>
							<span className='skills__level'>Advanced</span>
						</div>
					</div>
					<div className='skills__data'>
						<i className='bx bx-badge-check'></i>
						<div>
							<h3 className='skills__name'>Operating System</h3>
							<span className='skills__level'>Advanced</span>
						</div>
					</div>
					<div className='skills__data'>
						<i className='bx bx-badge-check'></i>
						<div>
							<h3 className='skills__name'>MS Excel</h3>
							<span className='skills__level'>Intermediate</span>
						</div>
					</div>
					<div className='skills__data'>
						<i className='bx bx-badge-check'></i>
						<div>
							<h3 className='skills__name'>IBM SPSS</h3>
							<span className='skills__level'>Basic</span>
						</div>
					</div>
				</div>
				<div className='skills__group'>
					<div className='skills__data'>
						<i className='bx bx-badge-check'></i>
						<div>
							<h3 className='skills__name'>AI/ML</h3>
							<span className='skills__level'>Basic</span>
						</div>
					</div>
					<div className='skills__data'>
						<i className='bx bx-badge-check'></i>
						<div>
							<h3 className='skills__name'>DBMS</h3>
							<span className='skills__level'>Advanced</span>
						</div>
					</div>
					<div className='skills__data'>
						<i className='bx bx-badge-check'></i>
						<div>
							<h3 className='skills__name'>OOPS Concept</h3>
							<span className='skills__level'>Advanced</span>
						</div>
					</div>
					<div className='skills__data'>
						<i className='bx bx-badge-check'></i>
						<div>
							<h3 className='skills__name'>Data Structures</h3>
							<span className='skills__level'>Advanced</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Backend;
