import Work1 from "../../assets/work1.jpg";
import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.png";
import Work4 from "../../assets/work4.png";
import Work5 from "../../assets/work5.jpg";
export const projectsData = [
	{
		id: 1,
		image: Work1,
		title: "BlogNation",
		category: "Full-stack",
		link: "https://blognation.onrender.com/",
		description: "M.E.R.N. , Vite, Flowbite, Render, Tailwind Css",
	},
	{
		id: 2,
		image: Work2,
		title: "Estatedotcom",
		category: "Full-Stack",
		link: "https://estatedotcom.onrender.com/",
		description: "M.E.R.N. , Vite, Flowbite, Render, Tailwind Css",
	},
	{
		id: 3,
		image: Work3,
		title: "Summarizer",
		category: "AI, Web-Dev",
		link: "https://summarizer.pages.dev/",
		description: "ReactJS, Vite, RapidAPI, OpenAI, Tailwind Css",
	},
	{
		id: 4,
		image: Work4,
		title: "Inner Thoughts",
		category: "app",
		link: "https://innerthoughts.vercel.app/",
		description: "ReactJS, HTML, TailwindCSS, BotPress, OpenAI",
	},
	// {
	// 	id: 5,
	// 	image: Work2,
	// 	title: "Coming Soon",
	// 	category: "",
	// 	link: "Coming Soon",
	// 	description: "",
	// },
];
// export const projectsNav = [
// 	{ name: "all" },
// 	{ name: "web" },
// 	{ name: "app" },
// 	{ name: "Full-Stack" },
// ];
